@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-Regular.ttf');
    font-display: swap;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-Bold.ttf');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-Medium.ttf');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-Black.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Raleway/static/Raleway-ExtraBold.ttf');
    font-weight: 800;
    font-display: swap;
}


:root{
    --primary-zk-color: #97c5c2; /* #1C4C9A */
    --secondary-zk-color: #2e6b7a;
    --primary-zkl-color: #f2f8fc;
    --secondary-zkl-color: #e8e9e8;
    --black-color: #000000;

}

*{
    padding: 0;
    margin: 0;
}

body{
    font-family: Raleway, sans-serif;
}

.text-raleway{
    font-family: Raleway, sans-serif;
}

.text-poppins{
    font-family: Poppins, sans-serif;
}

.header-logo{
    color: var(--secondary-zk-color);
}

.text-zk-primary{
    color: var(--primary-zk-color);
}
.text-zk-secondary{
    color: var(--secondary-zk-color);
}
.text-zk-black{
    color: var(--black-color);
}

.bg-zk-primary{
    background-color: var(--primary-zk-color);
}
.bg-zk-secondary{
    background-color: var(--secondary-zk-color);
}

.bg-zkl-primary{
    background-color: var(--primary-zkl-color);
}
.bg-zkl-secondary{
    background-color: var(--secondary-zkl-color);
}
.bg-secondary-zkl-color{
    background-color: var(--secondary-zkl-color);
}

.secondary-zkl-color{
    color: var(--secondary-zkl-color);
}

.w80px{
    width: 80px;
}

.home-services-img{
    /* width: 100%; */
    width: 100px;
    max-width: 200px;
}
.border-zk-primary{
    border-top: 3px solid var(--primary-zk-color);
    border: none;
    height: 3px;
    opacity: 1;
    color: var(--primary-zk-color);
    background-color: var(--primary-zk-color);

}
.border-zk-white{
    border-top: 2px solid #fff;
    border: none;
    height: 2px;
    opacity: 1;
    color: #fff;
    background-color: #fff;

}

.fs-zk-92{
    font-size: 92px;
}

.fs-zk-68{
    font-size: 68px;
}

.fs-zk-58{
    font-size:58px;
}

.fs-zk-56{
    font-size:56px;
}
.fs-zk-52{
    font-size:52px;
}

.fs-zk-48{
    font-size: 48px;
}

.fs-zk-40{
    font-size: 36px;
}
.fs-zk-35{
    font-size: 35px;
}

.fs-zk-32{
    font-size: 32px;
}

.fs-zk-30{
    font-size: 30px;
}

.fs-zk-28{
    font-size: 28px;
}
.fs-zk-26{
    font-size: 26px;
}
.fs-zk-24{
    font-size: 24px;
}
.fs-zk-22{
    font-size: 22px;
}

.fs-zk-20{
    font-size: 20px;
}

.fs-zk-18{
    font-size: 18px;
}

.fs-zk-16{
    font-size: 16px;
}
.fs-zk-17{
    font-size: 17px;
}
.fs-zk-15{
    font-size: 15px;
}

.fs-zk-14{
    font-size: 14px;
}


.logo-img{
    height:60px;
}

.container-zk{
    max-width: 90%;
    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;
}

.container-zk2{
    max-width: 86%;
    padding-right: 7%;
    padding-left: 7%;
    margin-right: auto;
    margin-left: auto;
}

#website-nav .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

#website-nav .navbar-toggler{
    border: none;
    border-radius: 0;
    background-color: #fff;
}

#website-nav .nav-link{
    color: #000;
    padding-top: 0;
    padding-bottom: 0;
}

.padding-navlink{
    margin-right: 20px;
}


#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}

#website-nav .sub-nav-menu:hover .sub-sub-child-menu .dropdown-menu {
    display: none;
}

#website-nav .sub-nav-menu .dropdown-menu {
    margin-top: 0;
}
#website-nav .sub-child-menu:hover .dropdown-menu {
    display: block;
    visibility: visible;
    margin-top: -10px;
}

#website-nav .sub-child-menu .sub-sub-child-menu:hover .dropdown-menu{
    display: block;
    visibility: visible;
}

#website-nav .active{
    color: var(--secondary-zk-color);
}

#website-nav .dropdown-toggle{
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

.dropdown-item.active, .dropdown-item:active{
    background-color: var(--primary-zk-color);
}

#website-nav .sub-child-menu .dropdown-menu {
    /* display: none; */
    visibility: hidden;
    margin-top: 0;
}
#website-nav .sub-child-menu .dropdown-menu a{
    padding: 5px 10px;
    font-size: 15px;

}
#website-nav .sub-child-menu .dropdown-menu{
    width: 240px;
}
#website-nav .dropdown-item{
    cursor: pointer;
}
.navbar-offcanvas{
    /* background-color: var(--primary-zk-color);
    color: #fff; */
    padding: 0rem;
}

.take-control-bg{
    background-color: var(--primary-zkl-color) ;
    /* background color to -10vw */
    background-position: 0 -10vw;
}
.minusBg10 {
    margin-top: -100px;
}

.minusBg12 {
    margin-top: -120px;
}

.lh-1-2{
    line-height: 1.2;
}


.lb{
    margin-top: 30px;
    margin-left: 5px;
    padding-left: 30px;
    border-left: 4px solid #004bda;
}

.blog-img{
    width: 50%;
}

.slick-slide-title {
    color: var(--secondary-zk-color);
    padding: 10px;
    position: absolute;
    /* text-transform: ; */
    left: 8vw;
    font-size: 5.5vw;
    font-family: Raleway;
    font-weight: 700;
    top: 14%;
    width: 80%;
    line-height: 1.1;
  }
  .slick-slide-title-desc {
    color: var(--secondary-zk-color);
    padding: 10px;
    position: absolute;
    left: 8vw;
    font-size: 3.5vw;
    font-weight: 500;
    top: 48%;
    width: 80%;
  }
  .slick-slide-btn{
    position: absolute;
    left: 9vw;
    font-weight: 500;
    top: 67%;
    width: 80%;
  }

  .slick-slide-button{
    font-size: 1.4vw;
  }
  
.home-aim-bg{
    background: url(../images/Home/aim.webp) no-repeat;
    background-size: cover;
    background-position: center;
}

.home-review-bg{
    background: url(../images/Home/review.webp) no-repeat;
}

.about-timing-bg{
    background: url(../images/AboutUs/AboutTimingBg.webp) no-repeat;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
}


.bg-contact-opacity{
    background-color: #181818;
    opacity: 0.95;
}

.text-white{
    color: #ffffff;
}
.banner-section{
    position: relative;
}
.about-col-bg-white{
    background-color: #ffffff;
    border-radius: 10px;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}
.about-col-b{
    border: 3px solid #004bda;
    border-radius: 10px;
    align-content: center;
}

.w80px {
    width: 80px;
}

.border-header{
    border-bottom: 4px solid var(--secondary-zk-color) !important;
}

.border-zk-primary2 {
    background-color: var(--primary-zk-color);
    border: none;
    color: var(--primary-zk-color);
    height: 2px;
    opacity: 1;
}
.accordion-button{
    padding: 10px 20px !important;
}
.accordion-button:focus{
    box-shadow: 0;
}
.accordion-button:not(.collapsed){
    background-color: #ededed !important;
}
.accordion-button:focus{
    box-shadow: none !important;
}
.accordion-item {
    border-left: 0 !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
}

.accordion{
    border: 0;
}

.splash-screen {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f2f3f8;
    color: #5E6278;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
}
  
.splash-screen span {
color: #5E6278;
transition: none !important;
text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
}

.splash-screen img {
margin-left: calc(100vw - 100%);
margin-bottom: 30px;
height: 38px !important;
}

.slick-slide-image {
    max-width: 100%;
    height: auto;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  }
  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #01054e !important;
  }

  .slick-slide-content{
    color: #fff;
    position: absolute;
    left: 7vw;
    top: 30%;
    width: 45%;

}


.slider-wrapper {
    width: 100%;

    margin: auto;
  }

  .btn-zk-white{
    background-color: #fff;
    color: black;
    border: 0;
    border-radius: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
  }

.btn-zk-white:hover{
    background-color: rgb(238, 238, 238);
    color: black;
    border: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
}
.btn-zk-white:focus{
    background-color: rgb(238, 238, 238) !important;
    color: black !important;
}
.home-banner .row{
    margin: 0;
}

.footer-bg{
    background: url('../images/Home/footer.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.footer-links-section{
    padding-top: 200px;
    padding-bottom: 40px;
}
.border-zk-secondary-footer{
    background-color: var(--primary-zk-color);
    border: none;
    color: var(--secondary-zk-color);
    height: 3px;
    opacity: 1;
    width: 100px;
}
.border-gray2{
    border-top: 1px solid #ffffff40
}

  .rounded30{
    border-radius: 30px;
  }

  .rounded20{
    border-radius: 20px;
  }

  .rounded10{
    border-radius: 10px;
  }

  .rounded6{
    border-radius: 6px;
  }

  .btn-zk-outline-white{
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    /* height: 3em; */
    min-width: 120px;
    font-family: work-sans;
    font-weight: 600 !important;
    border: 1px solid #fff;

}
.btn-zk-primary{
    background-color: var(--primary-zk-color);
    color: #fff;
    cursor: pointer;
}

.btn-zk-secondary{
    background-color: var(--secondary-zk-color);
    color: #fff;
    cursor: pointer;
}


.btn-zk-primary-banner{
    background-color: var(--primary-zk-color);
    color: #fff;
    cursor: pointer;
    /* padding: 2vw 5vw; */
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
}

.btn-zk-primary-banner:hover{
    background-color: var(--primary-zk-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
    padding: 1vw 2vw;
}

.btn-zk-white-banner{
    background-color: #fff;
    color: var(--primary-zk-color);
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
    border-radius: 30px;
}

.btn-zk-white-banner:hover{
    background-color: #fdfdfd;
    opacity: 0.94;
    color: var(--primary-zk-color);
    cursor: pointer;
    padding: 1vw 2vw;
}
.home-background-banner{
    /* background-image: url('../images/home/banner.png'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 82vh;
    width: 100%;
    position: relative;
}
.home-banner-content{
    position: absolute;
    top: 15%;
    width: 55%;
    left: 13%;
}
.home-banner-heading{
    letter-spacing: 3px;
}
.home-banner-btn{
    font-size: 18px;
    border-radius: 30px !important;
}
.btn-zk-primary:hover{
    background-color: var(--primary-zk-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.btn-zk-secondary:hover{
    background-color: var(--secondary-zk-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.btn-zk-secondary:focus{
    background-color: var(--secondary-zk-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.shadow-zk-1{
    box-shadow: 2px 3px 3px 2px #dadada !important;
}

.padding10xem{
    padding: 10em 0;
}

.padding7xem{
    padding: 7em 0;
}

.padding5xem{
    padding: 5em 0;
}

.padding2-2xem{
    padding: 2.2em 0;
}
.padding3xem{
    padding: 3em 0;
}


.navbar-nav .dropdown-menu {
    background: #fdfdfd;
    border: rgb(255, 255, 255);
    box-shadow: #dadada 0px 0px 2px 0px;
    border-radius: 0;
    border-top: 2px solid var(--primary-zk-color);
    position: static;
    width: 100%;
}

#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}
#website-nav .sub-nav-menu .dropdown-menu {
    width: max-content;
    margin-top: 0;
}

.emr_featured_col{
    /* middle */
    min-height: 55px;

}

.card-stretch{
    height: calc(100% - 1px);
}

.service-healthcare-bg{
    background-image: url('../images/Services/healthcare-bg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
}

.home-mobile-pic{
    width: 30%;
}
.page-banner-title{
    color: #fff;
    /* padding: 10px; */
    position: absolute;
    /* font-family: Poppins; */
    font-weight: bolder;
    /* text-transform: uppercase; */
    right: 8vw;
    font-size: 4.2vw;
    line-height: 4.3vw;
    top: 33%;
    letter-spacing: 1px;
    /* width: 80%; */
  }
  .page-banner-text1{
    font-size: 2vw;
  }
  .page-banner-desc{
    font-size: 1.4vw;
    font-weight: 400;
    margin-top: 10px;
    /* margin-top: 1px; */




  }

.navbar-nav .dropdown-menu {
    box-shadow: 0;
    border-radius: 0;
    border: 0;
    padding: 10px;
}

.digital-marketing-icon{
    width: 50% !important;
}
.about-weuse-section{
    min-height: 175px !important;
}

.about-ourphilosophy{
    width: 130px;
    height: 120px;
}
.home-healthimages{
    width: 40%;
}
.home-practice-img{
    width: 100%;
}

.home-practice-animation{
    width: 50%;
}
.whoweserve-healthimages{
    width: 25%;
} 

.about-connection-sec{
    min-height: 460px;
}
.marginTopvitals1{
    margin-top: -180px;
}

.paddingTopvitals2{
    padding-top: 190px;
}

.p-top{
    padding-top: 100px;
}

.p-bottom{
    padding-bottom: 100px;
}

.section1{
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}

.section2{
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}

.bg-os{
    background-color: #f2f5f7;
}

.bg-middle-banner{
    background-image: url(../images/AboutUs/MiddleBanner.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg-home-about{
    background-image: url(../../assets/images/Home/BgImg.webp);
}

.bg-primarycare-section1{
    background-image: url(../../assets/images/PrimaryCare/PrimaryCareSection1.webp);
}

.bg-urgentcare-section1{
    background-image: url(../../assets/images/UrgentCare/UrgentCareSection1.png);
}
.bg-skincancer-section1{
    background-image: url(../../assets/images/SkinCancer/SkinCancerSection1.png);
}

.bg-cardiology-section1{
    background-image: url(../../assets/images/Cardiology/cardiologySection1.png);
}

.bg-vaccination-section1{
    background-image: url(../../assets/images/Vaccinations/vaccinationSection1.png);
}

.bg-pediatrics-section1{
    background-image: url(../../assets/images/PediatricCare/pediatricsSection1.png);
}

.bg-behavioralhealth-section1{
    background-image: url(../../assets/images/BehavioralHealth/behavioralHealthSection1.png);
}

.bg-internalmedicine-section1{
    background-image: url(../../assets/images/InternalMedicine/internalMedicineSection1.png);
}

.bg-primarycare-section2{
    background-image: url(../../assets/images/PrimaryCare/PrimaryCareSection2.webp);
}

.bg-urgentcare-section2{
    background-image: url(../../assets/images/UrgentCare/UrgentCareSection2.png);
}
.bg-skincare-section2{
    background-image: url(../../assets/images/SkinCancer/SkinCancerSection2.png);
}

.bg-cardiology-section2{
    background-image: url(../../assets/images/Cardiology/cardiologySection2.png);
}

.bg-vaccination-section2{
    background-image: url(../../assets/images/Vaccinations/vaccinationSection2.png);
}

.bg-pediatrics-section2{
    background-image: url(../../assets/images/PediatricCare/pediatricsSection2.png);
}

.bg-behavioralhealth-section2{
    background-image: url(../../assets/images/BehavioralHealth/behavioralHealthSection2.png);
}

.bg-internalmedicine-section2{
    background-image: url(../../assets/images/InternalMedicine/internalMedicineSection2.png);
}

.bg-about{
    background-image: url(../../assets/images/AboutUs/BannerImg.webp);
}

.col-star{
    color: #fb8e29;
}



.table-bordered{
    border: 1px solid #d3d3d3 !important;
}

.border-bottom{
    border-bottom: 1px solid var(--secondary-zk-color) !important;  
}

.shadow-os{
   box-shadow: -3px 3px 4px 1px rgb(0 0 0 / 30%);
}

.icon-size{
    width: 51px;
}


.fw-900{
    font-weight: 900;
}

.fw-800{
    font-weight: 800;
}

.fw-700{
    font-weight: 700;
}


.fw-600{
    font-weight: 600;
}

.fw-500{
    font-weight: 500;
}

.fw-400{
    font-weight: 400;
}

.section1 .row{
    margin-right: 0;
    margin-left: 0;
}

.btn-zk-white-secondary{
    background-color: #fff;
    border: 3px solid var(--primary-zk-color);
}
.btn-zk-white-secondary:hover{
    background-color: var(--secondary-zk-color);
    color: #fff;
}

.ls-lg-zk-aim{
    letter-spacing: 16px;
}

.provider-img{
    width: 70%;
}

.provider-bg{
    background: linear-gradient(var(--primary-zk-color) 0 50%, white 50% 100%);
    border-radius: 6%;
    padding: 40px 10px 20px 10px;
    box-shadow: 2px 3px 3px 2px #dadada !important;
    margin: 0;
}
.provider-pic-w{
    max-width: 65%;
}
.provider-sec-bg{
    background-color: #F2F5F7;
}

.provider-col-bg{
    background: white;
    border-radius: 0%;
    padding: 20px 10px 5px 10px;
    box-shadow: 1px 1px 2px 2px #dadada;
    margin: 0;
}
.pa{
    position: absolute;
    top: -450px;
}

.home-insurance-img{
    width: 75%;
}

@media screen and (max-width: 1440px) {
    .home-marketing-section-content .fs-zk-16{
        font-size: 15px;
    }

    #website-responsive .left-padding7rem{
        padding-left: 7rem;
    }
    
    #website-responsive .right-padding7rem{
        padding-right: 7rem;
    }
    
}

@media screen and (max-width: 1281px) {
    .home-marketing-section-content .fs-zk-16{
        font-size: 15px;
    }
    
}
@media screen and (max-width: 1150px){

}

@media screen and (max-width: 1050px){
    #website-responsive .fs-zk-92{
        font-size: 55px;
    }

    #website-responsive .fs-zk-68{
        font-size: 45px;
    }

    .ls-lg-zk-aim{
        letter-spacing: 10px;
    }

}

@media screen and (max-width: 991px) {
    .blog-img{
        width: 100%;
    }
    .provider-bg{
        background: linear-gradient(var(--primary-zk-color) 0 30%, white 70% 100%);
    }
    .home-services-img{
        width: 120px;
    }
    .home-mobile-pic{
        width: 30%;
    }
    .logo-img{
        height:45px;
    }
    #website-responsive .minusBg10, #website-responsive .minusBg12 {
        margin-top: 0;
    }

    .home-insurance-img{
        width: 60%;
    }

    #website-responsive .fs-zk-92{
        font-size: 55px;
    }
    #website-responsive .service-healthcare-bg  .fs-zk-92{
        font-size: 45px;
    }

    #home-we-aim .fs-zk-92{
        font-size: 45px;
    }

    #website-responsive .fs-zk-68{
        font-size: 45px;
    }

    #website-responsive .fs-zk-56, #website-responsive .fs-zk-58{
        font-size:40px;
    }

    #website-responsive .fs-zk-52{
        font-size:38px;
    }


    #website-responsive .fs-zk-48{
        font-size:36px;
    }

    #website-responsive .fs-zk-40{
        font-size:32px;
    }

    #website-responsive .fs-zk-35{
        font-size:28px;
    }

    #website-responsive .padding10xem, #website-responsive .padding7xem, #website-responsive .padding5xem{
        padding: 3em 0;
    }

    #website-responsive .padding3xem{
        padding: 2em 0;
    }

    /* .btn-zk-white{
        padding: 0;
    }
    .btn-zk-white:hover{
        padding: 0;
    }
    .btn-zk-white button{
        padding: 0;
    } */
    .page-banner-title{
        top:25%;
    }
    .about-weuse-section1, .about-weuse-section2, .about-weuse-section3, .about-weuse-section4{
        width: 100%;
        height: unset;
        min-height: unset;
        display: block;
        padding: 40px 30px !important;
        font-weight: 600;
    }
    .navbar{
        padding: unset;
    }
    
    /* .home-banner-content{
        position: absolute;
        top: 15%;
        width: 50%;
        left: 3%;
    } */
}

@media screen and (max-width: 768px) {
    .provider-pic-w{
        max-width: 150px;
    }
    .integrated-section-img-1, .integrated-section-img-2, .integrated-section-img-3, .integrated-section-img-4{
        background-size: cover;
        background-attachment: unset;
    }
    .review-section-img-1, .review-section-img-2, .review-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }
    .appointment-section-img-1, .appointment-section-img-2, .appointment-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }

    #website-responsive .minusBg10 {
        margin-top: 0;
    }
    .footer-logo{
        height: 50px;
    }
    .home-practice-img{
        width: 40%;
    }
    .home-practice-animation{
        width: 70%;
    }

    #website-responsive .fs-zk-20{
        font-size:16px;
    }
    .slick-slide-content{
        top: 35%;
        width: 64%;
        left: 2vw;
    }
    .slick-slide-title-desc{
        font-size: 2.7vw;
        font-weight: 500;
        padding: 0 10px 3px 10px;
    }

    .slick-slide-title{
        font-size: 5.4vw;
        padding: 0 10px;
        top:27%;
        line-height: 40px;
    }
    .slick-slide-btn{
        top: 60%;
    }
    
    .slick-slide-button{
        font-size: 2vw;
      }

    .home-banner-btn{
        font-size: 12px !important;
    }
    .page-banner-btn{
        margin-top: 10px;
    }
    .btn-zk-white-banner{
        font-size: 13px;
        /* margin-top: 20px; */
    }
    .btn-zk-primary-banner{
        font-size: 14px;
        margin-top: 20px;
    }
    
    .digital-marketing-icon{
        width: 60% !important;
    }
    .about-weuse-section{
        min-height: 100% !important;
    }
    .home-healthimages{
        width: 30%;
    }

    .home-healthimages img{
        width: 30%;
    }
    .page-banner-title{
        font-size: 5.5vw;
        top: 47%;
        line-height: 5.5vw;
    }
    .page-banner-text1{
        font-size: 3vw;
    }
    .page-banner-desc{
        font-size: 3vw;
        font-weight: 500;
        display: none;
        line-height: 3vw;
    }
    .about-connection-sec{
        min-height: 100%;
    }
    .text-home-onestop{
        font-size: 24px !important; 
    }
    .about-ourphilosophy{
        width: 100px;
        height: 80px;
    }
    #reviews-lb .lb{
        margin-top: 7px;
        margin-left: 0;
        padding-left: 0;
        border-left: unset;
    }

    .marginTopvitals1{
        margin-top: -110px;
    }
    
    .paddingTopvitals2{
        padding-top: 120px;
    }

    .emr_featured_col{
        /* middle */
        min-height: 0px;
    
    }
    .footer-links-section{
        padding-bottom: 0;
    }
    .ls-lg-zk-aim{
        letter-spacing: 2px;
    }

    .home-insurance-img{
        width: 50%;
    }
    .about-timing-bg{
        background: none;
    }
    
}
  

@media screen and (max-width: 576px) {
    .section1{
        min-height: 100%;
    }
    .section2{
        min-height: 100%;
    }
    .provider-img{
        width: 40%;
    }

    .slick-slide-title{
        font-size: 6.5vw;
        top:20%;
        line-height: 30px;

    }

    .slick-slide-title-desc{
        top:45%;
        font-size: 4vw;
        font-weight: 500;
        
    }

    .slick-slide-btn{
        top: 60%;
        margin-left: 10px;
    }
    

    .slick-slide-button{
        font-size: 3.5vw;
      }

      .home-services-img{
        width:120px;
      }
    .home-mobile-pic{
        width: 50%;
    }

    #website-responsive .fs-zk-92{
        font-size: 45px;
    }

    #website-responsive .service-healthcare-bg  .fs-zk-92{
        font-size: 35px;
    }

    #home-we-aim .fs-zk-92{
        font-size: 35px;
    }

    #services-cta .fs-zk-92{
        font-size: 35px;
    }

    #website-responsive .fs-zk-68{
        font-size: 32px;
    }

    
    #website-responsive .fs-zk-56, #website-responsive .fs-zk-58{
        font-size:28px;
    }
    #website-responsive .fs-zk-52{
        font-size:27px;
    }
    
    #website-responsive .fs-zk-48{
        font-size: 26px;
    }

    #website-responsive .fs-zk-40{
        font-size: 24px;
    }

    #website-responsive .fs-zk-35{
        font-size: 22px;
    }

    #website-responsive .fs-zk-30, #website-responsive .fs-zk-28{
        font-size: 20px;
    }

    #website-responsive .fs-zk-26, #website-responsive .fs-zk-24{
        font-size: 19px;
    }

    .navbar-brand .fs-zk-26{
        font-size: 19px;
    }

    #website-responsive .fs-zk-22{
        font-size: 18px;
    }
    #website-responsive .fs-zk-18, #website-responsive .fs-zk-17{
        font-size:16px;
    }
    .about-health-section .fs-zk-30{
        font-size: 23px !important;
    }

    .container-zk, .container-zk2{
        max-width: 96%;
        padding-right: 2% !important;
        padding-left: 2% !important;
        margin-right: auto;
        margin-left: auto;
    }
    
}



@media screen and (max-width: 480px) {
    .page-banner-title{
        top:47%;
    }
}

@media screen and (max-width: 440px) {
    .page-banner-title{
        top:47%;
    }
}

@media screen and (max-width: 400px) {
    .page-banner-title{
        top:47%;
    }
}

@media screen and (max-width: 320px) {
    .page-banner-title{
        top:35%;
    }
    
}

@media screen and (max-width: 280px) {
    
}

